html,
body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 40px;
}

.landing {
    width: 420px;
    padding: 50px;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow:
        0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.form {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.form input {
    height: 50px;
    font-size: 16px;
}

.boxes {
    width: 500px;
    display: flex;
    gap: 20px;
}

.box {
    width: 150px;
    border: 1px solid #f7f7f7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-align: center;
}

.box span {
    padding: 5px 0;
}

.login {
    text-align: center;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 5px;
    color: #c1c7d0;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #dfe1e6;
}

.separator:not(:empty)::before {
    margin-right: 5px;
}

.separator:not(:empty)::after {
    margin-left: 5px;
}

.footer {
    font-size: 11px;
    color: #888;
    font-weight: 400;
    position: absolute;
    width: 320px;
    bottom: 10px;
    text-align: center;
}

.footer a {
    color: rgb(139, 139, 255);
}

.terms {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
}

.gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition:
        background-color 0.218s,
        border-color 0.218s,
        box-shadow 0.218s;
    transition:
        background-color 0.218s,
        border-color 0.218s,
        box-shadow 0.218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity 0.218s;
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow:
        0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow:
        0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #b5b5b5;
}

@media only screen and (max-width: 600px) {
    .landing {
        width: 100%;
        box-shadow: none;
        border: none;
    }

    .wrapper {
        padding: 20px;
    }

    .form {
        flex-direction: column;
    }

    .form * {
        height: 50px !important;
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .boxes {
        display: none;
    }
}
